import { Link } from "react-router-dom";
import useSWR from "swr";
import "../styles/components/Header.component.css";
import fetcher from "../utils/fetcher";
import { FRONT_IMAGE_API } from "../utils/api";

function Header() {
  const { data } = useSWR(FRONT_IMAGE_API, fetcher);

  return (
    <header>
      <div className="header-container">
        <div className="container admin-menu-container">
          <Link to="/">
            <span>
              <b>HOME</b>
            </span>
          </Link>
          <Link to="/admin">
            <span>
              <b>ADMIN</b>
            </span>
          </Link>
        </div>
      </div>

      <div className="front-container container">
        <h1>
          <Link to="/">모텔매매 119</Link>
        </h1>
        <div>
          <h3 style={{ color: "blue" }}>모텔매매 최고 전문가</h3>
          <h3 style={{ fontSize: "1.38rem", color: "red" }}>
            파실분, 사실분 항상 연락 주세요
          </h3>
          <h3>
            박사장{" "}
            <span style={{ color: "red", fontSize: "2.5rem" }}>
              010-9150-6245
            </span>
          </h3>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #2c3e50", width: "100%" }} />

      <div className="container">
        <img
          crossorigin="anonymous"
          className="front-image"
          src={data?.site?.imageUrl}
          alt="front"
        />
      </div>
    </header>
  );
}

export default Header;
