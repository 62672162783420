import '../styles/components/Footer.component.css';

function Footer() {
  return (
    <footer>
      <div className="footer-container container">
        <p>
          더에이치부동산중개법인 서울특별시 강남구 역삼로 555번지
          102호.&nbsp;&nbsp;대표: 이창열&nbsp;&nbsp;등록번호: 11680-2021-00511&nbsp;&nbsp;소속중개보조원
        </p>
        <p></p>
        <p>Copyright motel119.com 2023. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer;
