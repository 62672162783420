import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import "../styles/Motel.css";
import { MOTEl_ONE_API } from "../utils/api";
import fetcher from "../utils/fetcher";

function Motel() {
  const { id } = useParams();
  const { data } = useSWR(`${MOTEl_ONE_API}/${id}`, fetcher);

  if (data) {
    return (
      <div className="motel-page-container">
        <h4>매물정보</h4>
        <div className="motel-table-container">
          <table>
            <tbody>
              <tr>
                <th>매물위치</th>
                <td>{data.address}</td>
                <th>매물번호</th>
                <td>{data.numberOfItem}</td>
              </tr>
              <tr>
                <th>대지면적</th>
                <td>{data.landArea}평</td>
                <th>연건평</th>
                <td>{data.grossFloorArea}평</td>
              </tr>
              <tr>
                <th>건축연도</th>
                <td>{data.yearsOfConstruction}년</td>
                <th>방개수</th>
                <td>{data.numberOfRooms}개</td>
              </tr>
              <tr>
                <th>월매출</th>
                <td>{data.monthlySales}천만원</td>
                <th>층수</th>
                <td>{data.numberOfFloors}층</td>
              </tr>
              <tr>
                <th>주차대수</th>
                <td>{data.numberOfParkingSpaces}대</td>
                <th>매매가</th>
                <td>{data.salePrice}억원</td>
              </tr>
              <tr>
                <th>대출가능액</th>
                <td>{data.availableAmountForLoan}억원</td>
                <th>실인수가</th>
                <td>{data.takeoverAmount}억원</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ position: "relative" }}>
          <img
            crossorigin="anonymous"
            src={data.imageURL}
            alt="motel preview"
            className="motel-image"
          />
          <p className="image-alt">본건 매물과 관련 없는 이미지임</p>
        </div>

        <br />
        <div className="card">
          <div className="card-body">
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
        </div>

        <br />
        <Link to="/">
          <button type="button" className="btn btn-link">
            목록으로
          </button>
        </Link>
      </div>
    );
  }

  return null;
}

export default Motel;
