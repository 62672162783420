import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoginToken } from "./loginToken";

function useRequireLogin() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const userToken = getLoginToken();
    if (!userToken) {
      navigate("/admin/login");
    } else {
      setToken(userToken);
    }
  }, [navigate]);

  return token;
}

export default useRequireLogin;
