import "../../styles/admin/Write.css";
import "react-quill/dist/quill.snow.css";
import { Button, Card, Input, Space } from "antd";
import { Typography } from "antd";
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import useRequireLogin from "../../utils/useRequireLogin";
import { MOTEl_ONE_API, WRITE_MOTEL } from "../../utils/api";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

function Write({ edit }) {
  const token = useRequireLogin();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form, setForm] = useState({});
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);

  const formChanged = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  useEffect(() => {
    const fetcher = async () => {
      if (edit) {
        const result = await fetch(`${MOTEl_ONE_API}/${id}`);
        const body = await result.json();

        setForm({ ...body, image: undefined });
        setDescription(body.description);
      }
    };

    fetcher();
  }, [edit, id]);

  const onUploadClicked = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("image", form.image);
    formdata.append("address", form.address);
    formdata.append("landArea", form.landArea);
    formdata.append("grossFloorArea", form.grossFloorArea);
    formdata.append("yearsOfConstruction", form.yearsOfConstruction);
    formdata.append("numberOfRooms", form.numberOfRooms);
    formdata.append("monthlySales", form.monthlySales);
    formdata.append("numberOfFloors", form.numberOfFloors);
    formdata.append("salePrice", form.salePrice);
    formdata.append("availableAmountForLoan", form.availableAmountForLoan);
    formdata.append("takeoverAmount", form.takeoverAmount);
    formdata.append("description", description);
    formdata.append("numberOfParkingSpaces", form.numberOfParkingSpaces);

    if (edit) {
      formdata.append("numberOfItem", id);
    }

    const requestOptions = {
      method: edit ? "PATCH" : "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(WRITE_MOTEL, requestOptions)
      .then((response) => response.text())
      .then(() => {
        setForm({});
        setLoading(false);
        navigate("/admin");
      })
      .catch((error) => window.alert(error));
  };

  return (
    <div className="writer-container">
      {!edit ? (
        <Title style={{ marginTop: 32, marginBottom: 32 }}>새 글 쓰기</Title>
      ) : (
        <Title style={{ marginTop: 32, marginBottom: 32 }}>
          글 수정 '{id}번'
        </Title>
      )}

      <Card title="건물 기본 정보">
        <Space direction="vertical">
          <Input
            addonBefore="소재지"
            placeholder="강원특별자치도 춘천시 효자동"
            onChange={(e) => formChanged("address", e.target.value)}
            value={form.address}
          />
          <Space direction="horizontal">
            <Input
              addonBefore="대지면적"
              placeholder="100"
              addonAfter="평"
              onChange={(e) => formChanged("landArea", e.target.value)}
              value={form.landArea}
            />
            <Input
              addonBefore="연건평"
              placeholder="100"
              addonAfter="평"
              onChange={(e) => formChanged("grossFloorArea", e.target.value)}
              value={form.grossFloorArea}
            />
            <Input
              addonBefore="건축년도"
              placeholder="2000"
              addonAfter="년"
              onChange={(e) =>
                formChanged("yearsOfConstruction", e.target.value)
              }
              value={form.yearsOfConstruction}
            />
          </Space>
          <Space direction="horizontal">
            <Input
              addonBefore="방개수"
              placeholder="100"
              addonAfter="개"
              onChange={(e) => formChanged("numberOfRooms", e.target.value)}
              value={form.numberOfRooms}
            />
            <Input
              addonBefore="월매출"
              placeholder="1"
              addonAfter="천만원"
              onChange={(e) => formChanged("monthlySales", e.target.value)}
              value={form.monthlySales}
            />
            <Input
              addonBefore="층수"
              placeholder="10"
              addonAfter="층"
              onChange={(e) => formChanged("numberOfFloors", e.target.value)}
              value={form.numberOfFloors}
            />
            <Input
              addonBefore="주차대수"
              placeholder="5"
              addonAfter="대"
              onChange={(e) =>
                formChanged("numberOfParkingSpaces", e.target.value)
              }
              value={form.numberOfParkingSpaces}
            />
          </Space>
          <Space direction="horizontal">
            <Input
              addonBefore="매매가"
              placeholder="5"
              addonAfter="억원"
              onChange={(e) => formChanged("salePrice", e.target.value)}
              value={form.salePrice}
            />
            <Input
              addonBefore="대출가능액"
              placeholder="1"
              addonAfter="억원"
              onChange={(e) =>
                formChanged("availableAmountForLoan", e.target.value)
              }
              value={form.availableAmountForLoan}
            />
            <Input
              addonBefore="실인수가"
              placeholder="10"
              addonAfter="억원"
              onChange={(e) => formChanged("takeoverAmount", e.target.value)}
              value={form.takeoverAmount}
            />
          </Space>
        </Space>
      </Card>
      <br />
      <Card title="건물 사진">
        <input
          type="file"
          onChange={(e) => formChanged("image", e.target.files[0])}
        />
        {
          form.imageURL && (
            <div style={{ marginTop: 32 }}>
              <p style={{ color: 'red', margin: 0 }}>업로드 된 이미지: </p>
              <img src={form.imageURL} width={250} crossorigin="anonymous" />
            </div>
          )
        }
      </Card>
      <br />
      <Card title="건물 설명" style={{ height: 400 }}>
        <ReactQuill
          style={{ height: 250 }}
          onChange={(val) => setDescription(val)}
          value={description}
        />
      </Card>
      <br />
      <Button onClick={onUploadClicked} disabled={loading}>
        완료 및 저장
      </Button>
    </div>
  );
}

export default Write;
