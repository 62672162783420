import { useEffect, useState } from 'react';
import '../../styles/admin/Login.css';
import { Alert, Button, Form, Input } from "antd";
import { getLoginToken, setLoginToken } from '../../utils/loginToken';
import { useNavigate } from 'react-router-dom';
import { ADMIN_LOGIN_API } from '../../utils/api';

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loginForm, setLoginForm] = useState({
    id: '',
    password: '',
  });

  useEffect(() => {
    const token = getLoginToken();
    if (token) {
      navigate("/admin");
    }
  }, [navigate])

  const onFinished = async () => {
    try {
      const result = await fetch(ADMIN_LOGIN_API, {
        method: "POST",
        body: new URLSearchParams(loginForm),
      });

      const newToken = (await result?.json()).token;
      if (!newToken || result.status !== 201) {
        setError("아이디 또는 비밀번호가 잘못되었습니다.");
        return;
      }

      setLoginToken(newToken);
      navigate("/admin");
    } catch (ex) {
      console.error(ex);
      setError("네트워크에 오류가 발생했습니다");
    }
  };

  return (
    <div className="login-container">
      {error && (
        <Alert message="Error" description={error} type="error" showIcon />
      )}

      <h1 className="login-header">관리자 로그인</h1>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        autoComplete="off">
        <Form.Item
          label="아이디"
          rules={[{ required: true, message: "사용자명을 입력하세요." }]}>
          <Input
            value={loginForm.id}
            onChange={(e) => setLoginForm({ ...loginForm, id: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label="비밀번호"
          rules={[{ required: true, message: "비밀번호를 입력하세요." }]}>
          <Input.Password
            value={loginForm.password}
            onChange={(e) =>
              setLoginForm({ ...loginForm, password: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button onClick={onFinished}>로그인</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login;