import '../styles/components/GalleryItem.component.css';

function GalleryItem({ imageUrl, address, roomCount, monthlySales }) {
  return (
    <div className="card gallery-container">
      <img crossorigin="anonymous" src={imageUrl} alt="preview" />
      <div>
        <p>{address}</p>
        <p>
          <span>방 {roomCount}개</span>
          &nbsp;&nbsp;
          <span>월 매출 {monthlySales}억원~</span>
        </p>
      </div>
    </div>
  )
}

export default GalleryItem;