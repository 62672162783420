import Navigation from "../components/Navigation.component";

function Layout({ children }) {
  return (
    <section className="container" style={{ marginTop: 32, display: "flex" }}>
      <Navigation />
      <div style={{ width: 32 }} />
      {children}
    </section>
  );
}

export default Layout;
