import { Link } from "react-router-dom";
import "../../styles/admin/Admin.css";
import {
  DELETE_MOTEL,
  FRONT_IMAGE_UPLOAD,
  MOTEL_LIST_API,
} from "../../utils/api";
import { Button, Card, Table, Upload, message } from "antd";
import useRequireLogin from "../../utils/useRequireLogin";
import { Typography } from "antd";
import useSWR from "swr";
import fetcher from "../../utils/fetcher";

function Admin() {
  const token = useRequireLogin();
  const { data } = useSWR(
    `${MOTEL_LIST_API}?page=1&order=item&size=2147483647`,
    fetcher
  );
  const { Title } = Typography;

  const onDelete = (id) => {
    fetch(`${DELETE_MOTEL}/${id}`, {
      headers: { Authorization: token },
      method: "DELETE",
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        window.alert(err);
      });
  };

  const props = {
    name: "image",
    action: FRONT_IMAGE_UPLOAD,
    headers: {
      Authorization: token,
    },
    method: "PUT",
    onChange(info) {
      if (info.file.status === "done") {
        window.location.reload(false);
      } else if (info.file.status === "error") {
        message.error(`대문 사진을 변경하지 못했습니다.`);
      }
    },
  };

  return (
    <div className="admin-container">
      <Title style={{ marginTop: 32, marginBottom: 32 }}>관리자 설정</Title>
      <Card title="대문 사진 변경">
        <p style={{ marginBottom: 16 }}>
          새 파일을 업로드하여 대문 사진을 변경합니다.
        </p>
        <Upload {...props}>
          <Button>여기를 클릭하여 파일을 선택하세요.</Button>
        </Upload>
      </Card>
      <br />
      <Card title="글 관리">
        <p style={{ marginBottom: 16 }}>글을 쓰거나 삭제 또는 수정합니다.</p>
        <Table
          dataSource={
            data?.motels?.motels
              ? data.motels.motels.map((motel) => ({
                  ...motel,
                  manage: (
                    <>
                      <Link to={`/motel/${motel.numberOfItem}`}>
                        <button
                          type="button"
                          className="btn btn-link btn-sm"
                          style={{ marginRight: 4 }}
                        >
                          보기
                        </button>
                      </Link>
                      <Link to={`/admin/edit/${motel.numberOfItem}`}>
                        <button
                          type="button"
                          className="btn btn-outline-warning btn-sm"
                          style={{ marginRight: 4 }}
                        >
                          수정
                        </button>
                      </Link>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => onDelete(motel.numberOfItem)}
                      >
                        삭제
                      </button>
                    </>
                  ),
                }))
              : []
          }
          style={{ minHeight: 600 }}
          columns={[
            {
              title: "매물번호",
              dataIndex: "numberOfItem",
              key: "numberOfItem",
            },
            {
              title: "지역",
              dataIndex: "address",
              key: "address",
            },
            {
              title: "총매도가 (억원)",
              dataIndex: "salePrice",
              key: "salePrice",
            },
            {
              title: "실인수가 (억원)",
              dataIndex: "takeoverAmount",
              key: "takeoverAmount",
            },
            {
              title: "월매출 (천만원)",
              dataIndex: "monthlySales",
              key: "monthlySales",
            },
            {
              title: "관리",
              dataIndex: "manage",
              key: "manage",
            },
          ]}
        />
        <Link to="/admin/write">
          <Button>새 글 쓰기</Button>
        </Link>
      </Card>
    </div>
  );
}

export default Admin;
