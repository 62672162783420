import { Route, Routes } from "react-router-dom";
import Header from "./components/Header.component";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Footer from "./components/Footer.component";
import Motel from "./pages/Motel";
import Login from "./pages/admin/Login";
import Admin from "./pages/admin/Admin";
import Write from "./pages/admin/Write";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/motel/:id" element={<Layout><Motel /></Layout>} />
      </Routes>
      <Routes>
        <Route path="/admin/login" element={<Layout><Login /></Layout>} />
        <Route path="/admin" element={<Layout><Admin /></Layout>} />
        <Route path="/admin/write" element={<Layout><Write /></Layout>} />
        <Route path="/admin/edit/:id" element={<Layout><Write edit={true} /></Layout>} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
