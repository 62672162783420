import GalleryItem from "../components/GalleryItem.component";
import useSWR from "swr";
import "../styles/Home.css";
import { MOTEL_LIST_API } from "../utils/api";
import { useState } from "react";
import fetcher from "../utils/fetcher";
import { Link, useSearchParams } from "react-router-dom";
import { Pagination } from "antd";

function Home() {
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    order: "item",
  });
  const [searchParams] = useSearchParams();

  const { data: galleryData } = useSWR(
    `${MOTEL_LIST_API}?order=item&page=1`,
    fetcher
  );
  const { data: motelData } = useSWR(
    `${MOTEL_LIST_API}?order=${paginationParams.order}&page=${
      paginationParams.page
    }${
      searchParams.get("price")
        ? `&start=${searchParams.get("price")}${
            Number(searchParams.get("price")) === 20
              ? ""
              : `&end=${Number(searchParams.get("price")) + 5}`
          }`
        : ""
    }`,
    fetcher
  );

  return (
    <div style={{ width: "100%" }}>
      <h1>추천 매물</h1>
      <div className="gallery-list-container">
        {galleryData?.motels?.motels &&
          galleryData.motels.motels.slice(0, 8).map((motel) => (
            <Link to={`/motel/${motel.numberOfItem}`}>
              <GalleryItem
                imageUrl={motel.imageURL}
                address={motel.address}
                roomCount={motel.numberOfRooms}
                monthlySales={motel.monthlySales}
                key={motel._id}
              />
            </Link>
          ))}
      </div>
      <br />
      <div className="btn-group" role="group" style={{ marginBottom: 8 }}>
        <button
          type="button"
          className={`btn btn${
            paginationParams.order === "item" ? "" : "-outline"
          }-dark`}
          style={{ width: 100 }}
          onClick={() =>
            setPaginationParams({ ...paginationParams, order: "item" })
          }
        >
          최신순
        </button>
        <button
          type="button"
          className={`btn btn${
            paginationParams.order === "address" ? "" : "-outline"
          }-dark`}
          style={{ width: 100 }}
          onClick={() =>
            setPaginationParams({ ...paginationParams, order: "address" })
          }
        >
          지역순
        </button>
        <button
          type="button"
          className={`btn btn${
            paginationParams.order === "sales" ? "" : "-outline"
          }-dark`}
          style={{ width: 100 }}
          onClick={() =>
            setPaginationParams({ ...paginationParams, order: "sales" })
          }
        >
          가격순
        </button>
      </div>
      <table className="table table-hover motel-list-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">지역</th>
            <th scope="col">총매도가</th>
            <th scope="col">실인수가</th>
            <th scope="col">월매출</th>
          </tr>
        </thead>
        <tbody>
          {motelData?.motels?.motels &&
            motelData.motels.motels.map((motel) => (
              <tr key={motel._id}>
                <th scope="row">{motel.numberOfItem}</th>
                <td>
                  <Link to={`/motel/${motel.numberOfItem}`}>
                    {motel.address}
                  </Link>
                </td>
                <td>
                  <Link to={`/motel/${motel.numberOfItem}`}>
                    {motel.salePrice}억원
                  </Link>
                </td>
                <td>
                  <Link to={`/motel/${motel.numberOfItem}`}>
                    {motel.takeoverAmount}억원
                  </Link>
                </td>
                <td>{motel.monthlySales}천만원</td>
              </tr>
            ))}
        </tbody>
      </table>

      {!isNaN(motelData?.motels?.total) && (
        <Pagination
          defaultCurrent={1}
          current={paginationParams.page}
          total={motelData.motels.total}
          onChange={(page) =>
            setPaginationParams({ ...paginationParams, page })
          }
          pageSize={20}
        />
      )}
    </div>
  );
}

export default Home;
