import { Link } from "react-router-dom";
import "../styles/components/Navigation.component.css";

function Navigation() {
  return (
    <nav>
      <div className="card card-extra">
        <div className="card-body contact">
          <img
            src={require("../assets/tel.png")}
            width={80}
            height={80}
            alt="tel"
          />
          <p>문의 전화</p>
          <h3>010.9150.6245</h3>
        </div>
      </div>

      <div className="card card-extra">
        <div className="card-body">
          <h5 className="card-title">
            <b>모텔 매매</b>
          </h5>
        </div>
        <ul className="list-group list-group-flush extra-style">
          <li className="list-group-item">
            <Link to="/">돌아가기</Link>
          </li>
          <li className="list-group-item">
            <Link to="/?price=0">실인수가 5억 이하</Link>
          </li>
          <li className="list-group-item">
            <Link to="/?price=5">실인수가 10억 이하</Link>
          </li>
          <li className="list-group-item">
            <Link to="/?price=10">실인수가 15억 이하</Link>
          </li>
          <li className="list-group-item">
            <Link to="/?price=15">실인수가 20억 이하</Link>
          </li>
        </ul>
      </div>

      <div className="card card-extra">
        <div className="card-body">
          <h5 className="card-title">
            <Link to="/?price=20" className="expensive-motel">
              <center>
                <b>실인수가 20억 이상</b>
              </center>
            </Link>
          </h5>
        </div>
      </div>

      <div className="card card-extra">
        <div className="card-body">
          <h5 className="card-title">
            <b>관련 사이트</b>
          </h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <Link to="http://www.iros.go.kr/" target="_blank">
              대법원 등기열람
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://www.gov.kr/" target="_blank">
              정부24 (민원)
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="http://www.eum.go.kr/" target="_blank">
              토지이용계획확인원
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://rt.molit.go.kr/" target="_blank">
              국토부실거래가공개
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://www.courtauction.go.kr/" target="_blank">
              대법원경매사이트
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://sookbak.com/" target="_blank">
              숙박신문
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://www.goodchoice.kr/" target="_blank">
              여기어때
            </Link>
          </li>
          <li className="list-group-item">
            <Link to="https://www.yanolja.com/" target="_blank">
              야놀자
            </Link>
          </li>
        </ul>
      </div>

      <div className="card card-extra">
        <div className="card-body">
          <p className="introduce">
            상호/더에이치부동산중개법인 (대표공인중개사 이창열) 등록번호
            11680-2021-00511 주소/서울특별시 강남구 역삼로 555번지 102호. TEL
            (02) 556-7100 사업자번호 872-86-02329 대표공인중개사 이창열.
            소속중개보조원
          </p>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
